import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile } from '~styles/global'
import Section from './Section'
import TileArticle from '~components/TileArticle'
import { JNLetters, LargeLarr, LargeRarr } from './Svg'
import { AnimatePresence, motion } from 'framer-motion'
import Button from './Button'

const ArticleRow = ({ className, content, featureHeading, sliderMobile, allLink }) => {
	
	// Mobile slider
	const totalSlides = content?.articles?.length
	const [currentSlide, setCurrentSlide] = useState(0)
	const [panStart, setPanStart] = useState(0)
	const [panEnd, setPanEnd] = useState(0)

	const nextSlide = ()=> {
		if (currentSlide >= totalSlides -1) {
			setCurrentSlide(0)
		} else {
			setCurrentSlide(currentSlide + 1)
		}
	}
	const prevSlide = ()=> {
		if (currentSlide === 0) {
			setCurrentSlide(totalSlides -1)
		} else {
			setCurrentSlide(currentSlide - 1)
		}
	}
	
	function onPanStart(event, info) {
		setPanStart(info.point.x)
	}
	function onPanEnd(event, info) {
		setPanEnd(info.point.x)
	}
	
	useEffect(() => {
		if(panStart > panEnd) {
			prevSlide()
		} else if (panStart < panEnd) {
			nextSlide()
		}
	}, [panEnd])
		
	return (
		<Wrap className={className}>
			{content?.title &&
				<Header>
					<Heading css={css`
						padding: ${featureHeading ? 'var(--xl) 0' : 'var(--m) 0'};
					`}>
						{featureHeading && <FeatureHeading>{featureHeading}</FeatureHeading>} 
						<h2 className={`${featureHeading && 'h1'}`}>{content?.title}</h2>
					</Heading>
				</Header>
			}
			<Grid css={css`
				${mobile}{
					display: ${sliderMobile ? 'none' : 'block'}
				}
			`}>
				{content?.articles.map((article, i) => (
					<ArticleTile key={article._title} article={article}
						css={css`
							border-right: ${i % 3 === 2 ? 'none': '1px solid var(--black)'};
						`}/>
				))}
				<JNBox 
					css={css`
						grid-column: span ${3 - (content?.articles.length % 3)};
						display: ${(3 - (content?.articles.length % 3)) < 3 ? 'grid' : 'none'};
						${mobile}{
							display: none;
						}
					`}>
					<JNLogo/>
				</JNBox>
			</Grid>
			{allLink && 
				<Baseline>
					<Button className='h3' to={'/journal'}>Full index</Button>
				</Baseline>
			}
			<Slider css={css`
				display: none;
				${mobile}{
					display: ${sliderMobile ? 'block' : 'none'}
				}
			`} >
				<AnimatePresence exitBeforeEnter>
					<motion.div 
						onPanStart={onPanStart}
						onPanEnd={onPanEnd}
						key={currentSlide}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.6}}
					>
						<ArticleTile article={content?.articles[currentSlide]}/>
					</motion.div>
				</AnimatePresence>
				<Arrows>
					<ArrowButton
						onClick={()=> prevSlide()}
						css={css`&:hover{svg {transform: translateX(2px);}}`}
					><LargeLarr/></ArrowButton>
					<Button className='h3' to={'/journal'}>Full index</Button>
					<ArrowButton
						onClick={()=> nextSlide()}
						css={css`&:hover{svg {transform: translateX(2px);}}`}
					><LargeRarr/></ArrowButton>
				</Arrows>
			</Slider>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const Header = styled(Section)`
	border-bottom: 1px solid var(--black);
`	
const Heading = styled.div`
	text-align: center;
	grid-column: span 12;
	${mobile}{
		grid-column: span 6;
	}
`
const FeatureHeading = styled.h2`
	margin-bottom: var(--m);
`
const Grid = styled.h2`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	${mobile}{
		grid-template-columns: 1fr;
	}
`
const ArticleTile = styled(TileArticle)`
	border-bottom: 1px solid var(--black);
	display: block;
	${mobile}{
		border-right: none;
	}
`
const JNBox = styled.div`
	border-bottom: 1px solid var(--black);
	display: grid;
`
const JNLogo = styled(JNLetters)`
	height: 20px;
	align-self: center;
	justify-self: center;
`
const Baseline = styled.div`
	text-align: center;
	padding: var(--l) var(--m);
	border-bottom: 1px solid var(--black);
	${mobile}{
		display: none;
	}
`
const Slider = styled.div`
`
const Arrows = styled.div`
	display: flex;
	justify-content: space-between;	
	align-items: center;
	grid-column: span 6;
	padding: var(--m);
`
const ArrowButton = styled.button`
	svg {
		width: 20px;
		transform: translateX();
		transition: transform 0.3s;
	}
`

ArticleRow.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	featureHeading: PropTypes.string,
}

export default ArticleRow