import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile } from '~styles/global'
import Section from '~components/Section'
import Image from '~components/Image'
import Button from '~components/Button'
import RichText from './RichText'
import useBreakpoint from '~utils/useBreakpoint'

const TextImage = ({ className, content, reverse, imagePadding}) => {
	const { isMobile } = useBreakpoint()

	const desktopImageCol = () => {
		if(reverse && imagePadding){
			return '1/7'
		} else if (imagePadding){
			return '7/13'
		} else if(reverse) {
			return '1/7'
		} else {
			return '7/13'
		}
	}
	const desktopImageMarginLeft = () => {
		if(reverse && imagePadding){
			return '0'
		} else if (imagePadding){
			return '0'
		} else if(reverse) {
			return '-40px'
		} else {
			return '-20px'
		}
	}
	const desktopImageMarginRight = () => {
		if(reverse && imagePadding){
			return '0'
		} else if (imagePadding){
			return '0'
		} else if(reverse) {
			return '-20px'
		} else {
			return '-40px'
		}
	}

	return (
		<Wrap 
			className={className} 
			css={css`
				max-height: ${imagePadding ? '100vh' : 'none'};
		`}
		>
			<DesktopImage 
				image={content?.image} 
				aspectRatio={isMobile ? 1 : 0.66}
				cover
				css={css`
					grid-column: ${desktopImageCol()};
					margin-top: ${imagePadding ? '0px' :'calc(var(--xxxl) * -1)'};
					margin-left: ${desktopImageMarginLeft()};
					margin-bottom: ${imagePadding ? '0px' : 'calc(var(--xxxl) * -1)'};
					margin-right: ${desktopImageMarginRight()};
					max-height: ${imagePadding ? 'none' : '100vh'};
					min-height: ${imagePadding ? 'auto' : '730px'};
					> div {
						max-width: ${imagePadding ? '50.9vh' : 'none'};
						min-width: ${imagePadding ? '332px' : 'none'};
					}
					${mobile}{
						display: ${imagePadding ? 'none' : 'block'};
					}
			`}/>
			<Content 
				css={css`
					grid-column: ${reverse ? '8/12' : '2/6'};
					${mobile}{
						grid-column: span 6;
					}
			`}>
				<Top>
					<SubTitle> {content?.subtitle}</SubTitle>
					<Title> {content?.title}</Title>
				</Top>
				<MobileImageContainer>
					<MobileImage 
						image={content?.image} 
						aspectRatio={0.66}
						css={css`
							display: none;
							${mobile}{
								display: ${imagePadding ? 'block' : 'none'};
							}
						`}
					/>
				</MobileImageContainer>
				<Bottom>
					<Text>
						<RichText content={content?.text}/>
					</Text>
					{ content?.button?.text && 
						<Button 
							className='h3' 
							link={content?.button?.link}
							css={css`margin-top: var(--m);`}
						>
							{content?.button?.text}
						</Button>
					}
				</Bottom>
			</Content>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding-top: var(--xxxl);
	padding-bottom: var(--xxxl);
	border-bottom: 1px solid var(--black);
	${mobile}{
		padding-top: 0;
		padding-bottom: var(--l);
		max-height: none;
		min-height: auto;
	}
`
const DesktopImage = styled(Image)`
	grid-row: 1;
	> div {
		object-position: center;
		margin: 0 auto;
		${mobile}{
			max-width: none;
			min-width: auto;
		}
	}
	${mobile}{
		margin-top: 0px;
		margin-bottom: var(--xs);
		margin-left: -20px;
		margin-right: -20px;
		min-height: auto;
	}
`
const MobileImageContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: 20px;
`
const MobileImage = styled(Image)`
	display: none;
	${mobile}{
		display: block;
		grid-column: 2/6;
		margin-bottom: var(--xl);
	}
`
const Content = styled.div`
	padding-top: var(--l);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	grid-row: 1;
	${mobile}{
		grid-row: 2;
	}
`
const Top = styled.div`
	text-align: center;
`
const Bottom = styled.div`
	text-align: center;
	margin-bottom: var(--s);
`
const Title = styled.h1`
	margin-bottom: var(--xl);
`
const SubTitle = styled.h2`
	margin-bottom: var(--xl);
`
const Text = styled.div`
`
TextImage.propTypes = {
	className: PropTypes.string,
	content: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	reverse: PropTypes.bool,
	imagePadding: PropTypes.bool,
}

export default TextImage