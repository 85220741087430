import { useState, useEffect } from 'react'

const useWindow = () => {
	const [currentWindow, setCurrentWindow] = useState({})

	useEffect(() => {
		// setCurrentWindow({
		// 	...window,
		// })

		function handleResize() {
			setCurrentWindow(window)
		}

		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	// useEffect(() => {
	// 	console.log(currentWindow)
	// }, [currentWindow])

	return currentWindow
}

export default useWindow