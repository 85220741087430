import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from './Section'
import { LargeLarr, LargeRarr } from './Svg'
import { AnimatePresence, motion } from 'framer-motion'

const ReviewsCarousel = ({ className, content }) => {
	const [slide, setSlide] = useState(undefined)
	const [integer, setInteger] = useState(0)
	const reviewsLength = content?.reviews?.length
	const [panStart, setPanStart] = useState(0)
	const [panEnd, setPanEnd] = useState(0)

	useEffect(() => {
		setSlide(content?.reviews[integer])
	}, [content.reviews, integer])

	const nextSlide = ()=> {
		if (integer >= reviewsLength -1) {
			setInteger(0)
		} else {
			setInteger(integer + 1)
		}
	}

	const prevSlide = ()=> {
		if (integer === 0) {
			setInteger(reviewsLength -1)
		} else {
			setInteger(integer - 1)
		}
	}

	function onPanStart(event, info) {
		setPanStart(info.point.x)
	}
	function onPanEnd(event, info) {
		setPanEnd(info.point.x)
	}

	useEffect(() => {
		if(panStart > panEnd) {
			prevSlide()
		} else if (panStart < panEnd) {
			nextSlide()
		}
	}, [panEnd])

	return (
		<Wrap className={className}>
			<Title>{content.title}</Title>
			<LeftArrowContainer>
				<LeftArrow onClick={()=> prevSlide()}>
					<LargeLarr/>
				</LeftArrow>
			</LeftArrowContainer>
			<Reviews>
				<ReviewContainer>
					<AnimatePresence exitBeforeEnter>
						<motion.div 
							onPanStart={onPanStart}
							onPanEnd={onPanEnd}
							key={integer}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.6}}
						>
							<Review className='large'>
								“{slide?.review}”
							</Review>
						</motion.div>
					</AnimatePresence>
				</ReviewContainer>
				<AnimatePresence exitBeforeEnter>
					<motion.div 
						key={integer}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.6}}
					>
						<Name>{slide?.name}</Name>
					</motion.div>
				</AnimatePresence>
			</Reviews>
			<RightArrowContainer>
				<RightArrow onClick={()=> nextSlide()}>
					<LargeRarr/>
				</RightArrow>
			</RightArrowContainer>
			<Counter className='numbers'>
				{integer + 1}/{reviewsLength}
			</Counter>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding-top: var(--xxxxl);
	padding-bottom: var(--xxxl);
	border-bottom: 1px solid var(--black);
	${mobile}{
		padding-top: var(--xxxl);
		padding-bottom: var(--xxl);
	}
`
const Title = styled.h2`
	grid-column: span 12;
	text-align: center;
	margin-bottom: var(--xxxl);
	${mobile}{
		grid-column: span 6;
	}
`
const Reviews = styled.div`
	text-align: center;
	grid-column: 3/11;
	touch-action: none;
	${mobile}{
		grid-column: 1/7;
	}
`
const ReviewContainer = styled.div`
	display: flex;
	min-height: 220px;
	align-items: center;
	justify-content: center;
	margin-bottom: var(--l);
	${tablet}{
		min-height: 152px;
	}
`
const LeftArrowContainer = styled.div`
	grid-column: 1/2;
	justify-self: start;
	align-self: center;
	${mobile}{
		grid-row: 3;
		grid-column: 1/2;
	}
`
const LeftArrow = styled.button`
	width: 30px;
	svg{
		transition: transform 0.3s;
	}
	&:hover{
		svg{
			transform: translateX(-3px);
		}
	}
	${mobile}{
		width: 21px;
	}
`
const RightArrow = styled.button`
	width: 30px;
	svg{
		transition: transform 0.3s;
	}
	&:hover{
		svg{
			transform: translateX(3px);
		}
	}
	${mobile}{
		width: 21px;
	}
`

const RightArrowContainer = styled.div`
	grid-column: 12/13;
	justify-self: end;
	align-self: center;
	${mobile}{
		grid-row: 3;
		grid-column: 6/7;
	}
`
const Review = styled.h1`
`
const Name = styled.div`
	margin-bottom: var(--xxxl);
`
const Counter = styled.div`
	grid-column: span 12;
	text-align: center;
	${mobile}{
		grid-column: span 4;
	}
`
ReviewsCarousel.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ReviewsCarousel